import {
  getPreferencesSetting,
  savePreferencesSetting,
} from '@/apis/materialList'
import store from '@/store'
import type { Module } from 'vuex'

import type { IRootState } from '../../types'
import type { IStore } from './types'

const preferencesSetting: Module<IStore, IRootState> = {
  namespaced: true,
  state: {
    setting: {
      isDeductionBujian: false,
      isDeductionPaibanStore: false,
      isDeductionSurplus: false,
      isDeductionBaseMaterial: true,
    },
    isLoading: false,
  },
  mutations: {
    setSetting(state, val) {
      state.setting = val
    },
    setIsLoading(state, val) {
      state.isLoading = val
    },
  },
  actions: {
    async updateSetting({ state }) {
      if (!state.setting || !Object.keys(state).length) return
      await savePreferencesSetting(state.setting as any)
    },
    async getSetting({ commit }) {
      try {
        commit('setIsLoading', true)
        const result = await getPreferencesSetting(
          window.sessionStorage.getItem('thinkerx_material') as any,
          store.state.userInfo.platform
        )
        commit('setIsLoading', false)
        if (result.status !== 1) return
        const setting = result.data
        // 部分值的初始化
        setting.isBujianRecommend = setting.isBujianRecommend ?? true
        setting.isPaibanStoreRecommend = setting.isPaibanStoreRecommend ?? true
        setting.isSurplusRecommend = setting.isSurplusRecommend ?? true

        setting.isDeductionBujian = setting.isDeductionBujian ?? false
        setting.isDeductionPaibanStore = setting.isDeductionPaibanStore ?? false
        setting.isDeductionSurplus = setting.isDeductionSurplus ?? false
        setting.isDeductionBaseMaterial =
          setting.isDeductionBaseMaterial ?? true
        commit('setSetting', setting)
      } catch (error) {
        commit('setIsLoading', false)
      }
    },
  },
}

export default preferencesSetting
