import { getDeviceXDPI, mmToPx, pxTomm } from '@/util/exportFuncs'
import { defineObjProp, getPathMinMaxPoint } from '@/util/plankCommonFuncs'

import { signShape } from './tag-draw'

const dpi = getDeviceXDPI()
const excludeKeys = ['x', 'y', 'width', 'height']
// 前封边三角
const trianglePoint = [
  { x: 0, y: pxTomm(4, dpi) },
  { x: pxTomm(4, dpi), y: pxTomm(4, dpi) },
  { x: pxTomm(4, dpi) / 2, y: 0 },
]
// 映射封边信息合位置关系
const dirDataMap = {
  '←': 'left',
  '↓': 'bottom',
  '→': 'right',
  '↑': 'top',
}
// 真正要展示的位置和数据中的开门方向正好相反
const openDirMap = {
  left: 'right',
  right: 'left',
  top: 'bottom',
  bottom: 'top',
}
export const fontFamilyMap = {
  微软雅黑: 'msyh',
  黑体: 'simhei',
  宋体: 'simsun',
}
// 开门方向映射信息
function getOpenDirMapLocation(dir, xyReverse = false) {
  const data = {
    left: xyReverse ? 'bottom' : 'left',
    bottom: xyReverse ? 'left' : 'bottom',
    right: xyReverse ? 'top' : 'right',
    top: xyReverse ? 'right' : 'top',
  }
  return data[dir]
}
/**
 * @param { string | number } text 需要获取宽度的文字
 * @param {*} pt 文字大小
 * @returns 文字转换后的宽高
 */
export function dealElementWH(text, pt = 8, isAdd = true) {
  const span = document.createElement('span')
  span.innerText = text + ''
  span.style.fontSize = '12px'
  document.body.appendChild(span)
  const { offsetWidth, offsetHeight } = span
  document.body.removeChild(span)
  // +3是微调，直接使用pt的大小，就很噩梦
  return {
    offsetWidth: pxTomm((offsetWidth / 12) * (pt + (isAdd ? 3 : 0)), dpi),
    offsetHeight: pxTomm((offsetHeight / 12) * pt, dpi),
  }
}
// 旋转板件图
export function setRotate(obj1, obj2, angle) {
  if (obj1.rotate && obj2.rotateNum + '') {
    obj1.rotate(angle)
    obj2.rotateNum = obj2.rotateNum + angle / 90
  }
}
/**
 *
 * @param { Array } arr 当前数组
 * @param {*} curIdx 当前下标
 * @param {*} next 需要取出的下标位数  如 -2 向后两位下标取值 2 向前两位下标取值
 * @returns index 找到的下标 下标对应的值
 */
export function getArrayItem(arr, curIdx, next) {
  if (Object.prototype.toString.call(arr) !== '[object Array]') {
    throw new Error('arguments[0] is not Array')
  }
  let index
  if (next < 0) {
    index = (arr.length + curIdx - Math.abs(next)) % arr.length
  } else {
    index = (curIdx + next) % arr.length
  }
  return { index, res: arr[index] }
}

/**
 * @param { Array } path 需要缩放的xy数组
 * @param { number } scale
 * @param { number } defaultScale
 * @param { Object } part
 * @returns
 */
export function xyScale(path, scale, defaultScale, part) {
  if (!path.length) return
  let newPath = []
  if (Object.prototype.toString.call(path[0]) === '[object Object]') {
    const { startX, startY } = part
    for (const point of path) {
      const newPoint = {}
      newPoint.x = pxTomm((startX + point.x) * scale * defaultScale, dpi)
      newPoint.y = pxTomm((startY + point.y) * scale * defaultScale, dpi)
      newPath.push(newPoint)
    }
  } else {
    newPath = [...path].map((it) => pxTomm(it * scale * defaultScale, dpi))
  }
  return newPath
}
export class DocBaseShape {
  path
  mainShape
  // 最大矩形的中心点
  centrePoint = null
  // 四周点位
  aroundPoint = null
  #start
  constructor(mainShape, path, startX, startY) {
    this.resetParams(mainShape, path, startX, startY)
  }
  // 重置内部维护数据
  resetParams(mainShape, path, startX, startY) {
    this.path = path
    // 最大的矩形
    this.mainShape = JSON.parse(JSON.stringify(mainShape))
    if (mainShape.special) {
      Object.defineProperty(this.mainShape, 'special', {
        value: +mainShape.special,
        writable: true,
        enumerable: false,
      })
    }
    if (mainShape.hiddenBorder) {
      Object.defineProperty(this.mainShape, 'hiddenBorder', {
        value: mainShape.hiddenBorder,
        writable: true,
        enumerable: false,
      })
    }
    this.#start = {
      x: startX,
      y: startY,
    }
    this.#getCenter()
    this.#init()
  }
  #init() {
    // 初始化path
    for (let i = 0; i < this.path.length; i++) {
      if (Object.prototype.toString.call(this.path[i]) === '[object Object]')
        this.path[i] = DocBaseShape.rectMapPoint(this.path[i])
      for (const point of this.path[i]) {
        ;(point.x = point.x + this.#start.x),
          (point.y = point.y + this.#start.y)
      }
    }
    // 初始化最大的矩形
    for (const point of this.mainShape) {
      ;(point.x = point.x + this.#start.x), (point.y = point.y + this.#start.y)
    }
    // 如果是矩形补上一个起点点位
    if (this.mainShape.length == 4) this.mainShape.push(this.mainShape[0])
  }
  // 矩形转换成点位
  static rectMapPoint(rect) {
    const finalRect = [
      { x: rect.x, y: rect.y },
      { x: rect.x + rect.width, y: rect.y },
      {
        x: rect.x + rect.width,
        y: rect.y + rect.height,
      },
      { x: rect.x, y: rect.y + rect.height },
      { x: rect.x, y: rect.y },
    ]
    // 特殊字段重新添加
    Object.keys(rect).forEach((key) => {
      if (excludeKeys.includes(key)) return
      Object.defineProperty(finalRect, key, {
        value: rect[key],
        writable: true,
      })
    })
    if (Reflect.has(rect, 'special')) {
      Object.defineProperty(finalRect, 'special', {
        value: 102,
        writable: true,
      })
    }
    return finalRect
  }
  #getCenter() {
    if (Object.prototype.toString.call(this.mainShape) === '[object Object]') {
      this.mainShape = DocBaseShape.rectMapPoint(this.mainShape)
    }
    let xArr = [],
      yArr = []
    for (const point of this.mainShape) {
      if (point && Reflect.has(point, 'x') && Reflect.has(point, 'y')) {
        xArr.push(point.x)
        yArr.push(point.y)
      }
    }
    if (!xArr.length || !yArr.length) return []
    const x1 = Math.min(...xArr)
    const x2 = Math.max(...xArr)
    const y1 = Math.min(...yArr)
    const y2 = Math.max(...yArr)
    this.centrePoint = {
      x: Math.abs(x1 - x2) / 2 + this.#start.x,
      y: Math.abs(y1 - y2) / 2 + this.#start.y,
    }
    // 绘制图形周边的4边的中点
    this.aroundPoint = [
      {
        x: x1 + this.#start.x,
        y: Math.abs(y1 - y2) / 2 + this.#start.y,
        location: 'left',
      },
      {
        x: Math.abs(x1 - x2) / 2 + this.#start.x,
        y: y2 + this.#start.y,
        location: 'bottom',
      },
      {
        x: x2 + this.#start.x,
        y: Math.abs(y1 - y2) / 2 + this.#start.y,
        location: 'right',
      },
      {
        x: Math.abs(x1 - x2) / 2 + this.#start.x,
        y: y1 + this.#start.y,
        location: 'top',
      },
    ]
  }
  static dealPoint(curPoint, centerPoint, angle) {
    const { x: cx, y: cy } = curPoint
    const { x: dx, y: dy } = centerPoint
    const x =
      (cx - dx) * Math.cos((angle * Math.PI) / 180) -
      (cy - dy) * Math.sin((angle * Math.PI) / 180) +
      dx
    const y =
      (cx - dx) * Math.sin((angle * Math.PI) / 180) -
      (cy - dy) * Math.cos((angle * Math.PI) / 180) +
      dy
    return { ...curPoint, x, y }
  }
  // 实例可调用方法 旋转点位
  rotate(angle = 0) {
    const group = [this.mainShape, ...this.path, this.aroundPoint]
    for (const path of group) {
      for (let i = 0; i < path.length; i++) {
        path[i] = DocBaseShape.dealPoint(path[i], this.centrePoint, angle)
      }
    }
  }
}
// 组合图形绘制
export class DocCombinationShape extends DocBaseShape {
  constructor(mainShape, path, startX, startY) {
    super(mainShape, path, startX, startY)
  }
  drawGroup(carrier, cb, fillColor = 255) {
    const shapes = [this.mainShape, ...this.path]
    for (const shape of shapes) {
      for (let i = 0; i < shape.length; i++) {
        const point = shape[i]
        if (i != 0) {
          carrier.lineTo(point.x, point.y)
        } else {
          carrier.moveTo(point.x, point.y)
        }
      }
      if (Reflect.has(shape, 'special')) {
        carrier.setFillColor && carrier.setFillColor(0)
        Reflect.deleteProperty(shape, 'special')
      } else {
        carrier.setFillColor && carrier.setFillColor(fillColor)
      }
      Reflect.has(shape, 'hiddenBorder') ? carrier.fill() : carrier.fillStroke()
    }
    // 如有图形四周文字绘制的回调
    cb && cb(carrier, this.aroundPoint)
  }
}
// 三角图形绘制
export class DocTriangle extends DocBaseShape {
  constructor(mainShape, startX, startY) {
    super(mainShape, [], startX, startY)
  }
  drawTriangle(carrier, cb) {
    const [p1, p2, p3] = this.mainShape
    const [x1, y1, x2, y2, x3, y3] = [
      ...Object.values(p1),
      ...Object.values(p2),
      ...Object.values(p3),
    ]
    carrier.triangle && carrier.triangle(x1, y1, x2, y2, x3, y3, 'FD')
    cb && cb(carrier, this.mainShape)
  }
}
// 孔槽图形绘制
export class DocHoleSlot extends DocBaseShape {
  constructor(mainShape, holeSlotPoint, startX, startY) {
    super(mainShape, holeSlotPoint, startX, startY)
  }
  drawHole(carrier, cb, isDrawHoleSlotDir = false) {
    const shapes = [this.mainShape, ...this.path]
    for (const shape of shapes) {
      if (shape.length !== 1) {
        for (let i = 0; i < shape.length; i++) {
          const point = shape[i]
          if (i != 0) {
            carrier.lineTo(point.x, point.y)
          } else {
            carrier.moveTo(point.x, point.y)
          }
        }
        carrier.fillStroke()
        isDrawHoleSlotDir && cb && cb(carrier, shape)
      } else {
        const { x, y, radius } = shape[0]
        carrier.circle(x, y, radius)
        isDrawHoleSlotDir && cb && cb(carrier, shape[0])
      }
    }
    // 如有图形四周文字绘制的回调
    cb && cb(carrier, this.aroundPoint)
  }
}
// 孔槽图对应的绘制方法
export function drawPartHoleSlot(
  doc,
  part,
  startX,
  startY,
  info,
  other,
  jx_checked
) {
  doc.setFillColor(255)
  const { holeF, holeZ, defaultScale, uniqueID } = info
  const { oRect: rect } = part
  const { CONFIG, xyReverse, tempData } = other
  // 获取板件是否需要逆时针旋转
  let isRotatePlank
  const typographyKlass = tempData.find((it) => it.type === 'Typography')
  if (typographyKlass) {
    isRotatePlank = typographyKlass.data.plankRotate
  }
  // 获取子级
  const auxiliary = tempData.filter(
    (item) =>
      item.data &&
      item.data.uniqueID == uniqueID &&
      item.type == 'holeSlotPicture-size'
  )
  let pw = mmToPx(info.mWidth, dpi) / rect.width
  let ph = mmToPx(info.mHeight, dpi) / rect.height
  const slotHoleDefaultScale = Math.min(pw, ph) * defaultScale
  const partW = pxTomm(
    part.rect.width * slotHoleDefaultScale * CONFIG.scale,
    dpi
  )
  const partH = pxTomm(
    part.rect.height * slotHoleDefaultScale * CONFIG.scale,
    dpi
  )
  // 起始点
  // 需要显示的孔槽
  let allHole = [],
    allSlot = [],
    allCurveSlotList = []
  if (holeZ) {
    allHole = [...allHole, ...part.holes.filter((item) => item.side == 1)]
    allSlot = [...allSlot, ...part.slots.filter((item) => item.side == 1)]
    allCurveSlotList = [
      ...allCurveSlotList,
      ...(part.curveHoles
        ? part.curveHoles.filter(
            (item) => item.side == 1 && item.disType == 'CCCurveSlot'
          )
        : []),
    ]
  }
  if (holeF) {
    allHole = [...allHole, ...part.holes.filter((item) => item.side == -1)]
    allSlot = [...allSlot, ...part.slots.filter((item) => item.side == -1)]
    allCurveSlotList = [
      ...allCurveSlotList,
      ...(part.curveHoles
        ? part.curveHoles.filter(
            (item) => item.side == -1 && item.disType == 'CCCurveSlot'
          )
        : []),
    ]
  }
  let mainShape
  let shapes = []
  if (part.path) {
    let curveHoles = []
    if (part.curveHoles) {
      if (jx_checked) {
        part.curveHoles.forEach((item) => {
          item.path.forEach((point) => {
            if (xyReverse) {
              point.y = rect.height - point.y
            } else {
              point.x = rect.width - point.x
            }
          })
        })
      }
      curveHoles = part.curveHoles
        .filter((it) => it.deep >= part.thick)
        .map((it) => it.path)
    }
    let millInfos = []
    if (part.millInfo) {
      if (jx_checked) {
        part.millInfo.forEach((item) => {
          item.path.forEach((point) => {
            if (xyReverse) {
              point.y = rect.height - point.y
            } else {
              point.x = rect.width - point.x
            }
          })
        })
      }
      millInfos = part.millInfo
        .filter((it) => it.depth >= part.thick)
        .map((it) => it.shape)
    }
    const curveSlots = allCurveSlotList.map((slot) => slot.path)
    const resPath = [...part.path, ...curveHoles, ...millInfos, ...curveSlots]
    resPath.forEach((it, idx) => {
      const pointArr = it.map((p) => {
        return {
          x: pxTomm(p.x * slotHoleDefaultScale * CONFIG.scale, dpi),
          y: pxTomm(p.y * slotHoleDefaultScale * CONFIG.scale, dpi),
        }
      })
      if (idx === 0) {
        mainShape = pointArr
      } else {
        shapes.push(pointArr)
      }
    })
  } else {
    const x = 0
    const y = 0
    let width = pxTomm(
      part.oRect.width * slotHoleDefaultScale * CONFIG.scale,
      dpi
    )
    let height = pxTomm(
      part.oRect.height * slotHoleDefaultScale * CONFIG.scale,
      dpi
    )
    mainShape = { x, y, width, height }
  }
  // 绘制孔
  let drawHoles = []
  allHole.forEach((item) => {
    const { diameter, ocenter: center } = item
    const x = !(jx_checked && !xyReverse)
      ? pxTomm(center.x * slotHoleDefaultScale * CONFIG.scale, dpi)
      : pxTomm(
          (rect.width - center.x) * slotHoleDefaultScale * CONFIG.scale,
          dpi
        )
    const y = !(jx_checked && xyReverse)
      ? pxTomm(center.y * slotHoleDefaultScale * CONFIG.scale, dpi)
      : pxTomm(
          (rect.height - center.y) * slotHoleDefaultScale * CONFIG.scale,
          dpi
        )
    const radius = pxTomm(
      (diameter / 2) * slotHoleDefaultScale * CONFIG.scale,
      dpi
    )
    drawHoles.push([{ x, y, radius }])
  })
  // 绘制槽
  let drawSlots = []
  allSlot.forEach((item) => {
    const { opt1: pt1, opt2: pt2 } = item
    let slot = {}
    // 竖拉槽
    if (pt1.x == pt2.x) {
      slot.width = item.width
      slot.x = Math.min(pt1.x, pt2.x) - item.width / 2
      slot.long = Math.abs(pt1.y - pt2.y)
      slot.y = pt1.y > pt2.y ? pt2.y : pt1.y
    }
    // 横拉槽
    if (pt1.y == pt2.y) {
      slot.width = Math.abs(pt1.x - pt2.x)
      slot.y = Math.min(pt1.y, pt2.y) - item.width / 2
      slot.long = item.width
      slot.x = pt1.x > pt2.x ? pt2.x : pt1.x
    }
    const x = !(jx_checked && !xyReverse)
      ? pxTomm(slot.x * slotHoleDefaultScale * CONFIG.scale, dpi)
      : pxTomm((rect.width - slot.x) * slotHoleDefaultScale * CONFIG.scale, dpi)
    const y = !(jx_checked && xyReverse)
      ? pxTomm(slot.y * slotHoleDefaultScale * CONFIG.scale, dpi)
      : pxTomm(
          (rect.height - slot.y) * slotHoleDefaultScale * CONFIG.scale,
          dpi
        )
    const width = pxTomm(slot.width * slotHoleDefaultScale * CONFIG.scale, dpi)
    const height = pxTomm(slot.long * slotHoleDefaultScale * CONFIG.scale, dpi)
    drawSlots.push({ x, y, width, height })
  })

  const holeSlotPath = [...shapes, ...drawSlots, ...drawHoles]
  let groupStartX = startX,
    groupStartY = startY
  if (xyReverse) {
    groupStartX = startX - (partW - partH) / 2
    groupStartY = startY + (partW - partH) / 2
  }
  const holeSlot = new DocHoleSlot(
    mainShape,
    holeSlotPath,
    groupStartX,
    groupStartY
  )
  const holeSlotInstance = {
    info: auxiliary,
    rotateNum: 0,
  }
  if (xyReverse) setRotate(holeSlot, holeSlotInstance, -90)
  if (isRotatePlank) setRotate(holeSlot, holeSlotInstance, -90)
  const drawHoleSlotFunc = drawOtherInfo.bind(holeSlotInstance)
  holeSlot.drawHole(doc, drawHoleSlotFunc)
  function drawOtherInfo(carrier, aroundPoint) {
    const { fontPt, isShow, fontFamily } = this.info[0].data
    if (!isShow) return
    let [LE, BE, RE, TE] = ['', part.oRect.width, part.oRect.height, '']
    if (jx_checked && !xyReverse) {
      LE = part.oRect.height
      RE = ''
    }
    if (!jx_checked && xyReverse) {
      BE = ''
      TE = part.oRect.width
    }
    const fs = fontPt ?? 8
    carrier.setFontSize(fs)
    carrier.setFont(fontFamilyMap[fontFamily])
    const dirArr = ['left', 'bottom', 'right', 'top']
    // 记录当前那条边在左边
    let atLeft = null
    let index = 0
    for (const location of aroundPoint) {
      let { x, y, location: dir } = location
      let str = '',
        textBaseline = 'hanging'
      switch (dir) {
        case 'left':
          str = LE
          break
        case 'bottom':
          str = BE
          break
        case 'right':
          str = RE
          break
        case 'top':
          str = TE
          break
      }
      str = parseFloat(Number(str).toFixed(2)) + ''
      let { offsetWidth, offsetHeight } = dealElementWH(str, fs)
      if (!atLeft) {
        const res = getArrayItem(dirArr, 0, this.rotateNum)
        ;[index, atLeft] = [res.index, res.res]
      }
      // 0.5是微调
      if (dir === atLeft) {
        x -= offsetWidth
        y -= offsetHeight / 2
      } else if (dir === getArrayItem(dirArr, index, 1).res) {
        x -= offsetWidth / 2
        y += 0.5
      } else if (dir === getArrayItem(dirArr, index, -1).res) {
        x -= offsetWidth / 2
        y -= offsetHeight - 0.5
      } else {
        y -= offsetHeight / 2
      }
      if (+str) {
        carrier.text(str, x, y, { baseline: textBaseline })
      }
    }
  }
}

/**
 * @param { object } carrier 绘制的载体
 * @param { object } location 位置
 */
export function drawEdgeAndOtherInfo(scale, carrier, aroundPoint) {
  const aroundInfo = this.aroundInfo ?? ''
  const { lightEdgeInfo, model_front_edge } = this.part
  const lightEdgeArr = lightEdgeInfo
    ? lightEdgeInfo.split(/←|↓|→|↑/).filter((v) => v)
    : []
  let LE, BE, RE, TE
  if (typeof aroundInfo === 'string') {
    ;[LE, BE, RE, TE] = aroundInfo.split(/←|↓|→|↑/).filter((v) => v)
  } else {
    ;[LE, BE, RE, TE] = aroundInfo
  }
  const { plankRotate, showEdgeOff, showOpenDoor, textItems } = this.tempInfo
  const fs = (textItems[0].fontPt ?? 8) * scale
  carrier.setFontSize(fs)
  carrier.setFont(fontFamilyMap[textItems[0].fontFamily])
  const dirArr = ['left', 'bottom', 'right', 'top']
  // 记录当前那条边在左边
  let atLeft = null
  let index = 0
  aroundPoint.forEach((location, idx) => {
    let { x, y, location: dir } = location
    let str = '',
      textBaseline = 'top'
    switch (dir) {
      case 'left':
        str = LE
        break
      case 'bottom':
        str = BE
        break
      case 'right':
        str = RE
        break
      case 'top':
        str = TE
        break
    }
    str += ''
    if (str == 'undefined') {
      str = '0'
    }
    let { offsetWidth, offsetHeight } = dealElementWH(str, fs)
    if (!atLeft) {
      const res = getArrayItem(dirArr, 0, this.rotateNum)
      ;[index, atLeft] = [res.index, res.res]
    }
    // 0.5是微调
    let dx = x, // 开门方向
      dy = y
    // 开门方向圆的半径
    const circleRadius = pxTomm(8, dpi)
    if (dir === atLeft) {
      x -= offsetWidth
      y -= offsetHeight / 2
      dx -= offsetWidth + circleRadius
      dy -= offsetHeight / 2 - circleRadius / 2
    } else if (dir === getArrayItem(dirArr, index, 1).res) {
      x -= offsetWidth / 2
      dx += offsetWidth / 2 + circleRadius
      dy += circleRadius
    } else if (dir === getArrayItem(dirArr, index, -1).res) {
      x -= offsetWidth / 2
      y -= offsetHeight
      dx += offsetWidth / 2 + circleRadius
      dy -= offsetHeight / 2
    } else {
      y -= offsetHeight / 2
      dx += offsetWidth + circleRadius
      dy -= offsetHeight / 2 - circleRadius / 2
    }
    if (!(showEdgeOff && !Number(str))) {
      carrier.text(str, x, y, { baseline: textBaseline })
    }
    // 绘制开门方向
    const doorOpenSide = this.part.doorOpenSide ? this.part.doorOpenSide : false
    if (
      doorOpenSide &&
      dir == doorOpenSide &&
      showOpenDoor &&
      this.type !== 'plank'
    ) {
      drawOpenDir(this.doc, this.part, dx, dy, fs, circleRadius)
    }
    // 是见光边才进行绘制
    if (+lightEdgeArr[idx] && this.type !== 'plank') {
      // 绘制前封边三角
      let preEdgeStartX = x,
        preEdgeStartY = y
      if (dir == atLeft) {
        preEdgeStartY += offsetHeight
        preEdgeStartX += pxTomm(4, dpi) / 2
      } else if (dir == getArrayItem(dirArr, index, -2).res) {
        preEdgeStartY += offsetHeight
        preEdgeStartX += pxTomm(4, dpi)
      } else {
        preEdgeStartX -= offsetWidth / 2 + pxTomm(4, dpi) / 2
        preEdgeStartY += pxTomm(3, dpi) / 2
      }
      if (atLeft === dir && str.length === 1) {
        preEdgeStartX -= pxTomm(4, dpi) / 2
      }
      const triangle = new DocTriangle(
        trianglePoint,
        preEdgeStartX,
        preEdgeStartY
      )
      // 控制箭头全部朝外
      if (dir === atLeft) {
        triangle.rotate(-90)
      } else if (dir === getArrayItem(dirArr, index, 1).res) {
        triangle.rotate(-90)
        triangle.rotate(-90)
      } else if (dir === getArrayItem(dirArr, index, 2).res) {
        triangle.rotate(90)
      }
      triangle.drawTriangle(this.doc)
    }
    // 绘制前封边
    if (
      model_front_edge &&
      dirDataMap[model_front_edge] == dir &&
      this.type !== 'plank'
    ) {
      let formerStartX = x,
        formerStartY = y
      if (dir === getArrayItem(dirArr, index, 1).res) {
        formerStartY += offsetHeight
      } else {
        formerStartY -= offsetHeight
      }
      if (atLeft === dir && str.length === 1) {
        formerStartX -= pxTomm(2.5, dpi)
      }
      carrier.text('前', formerStartX, formerStartY, { baseline: textBaseline })
    }
  })
}
// 绘制开门方向
function drawOpenDir(carrier, part, startX, startY, pt, radius) {
  if (!part.doorOpenSide) return
  carrier.circle(startX, startY, radius)
  carrier.setFontSize(8)
  const { offsetWidth, offsetHeight } = dealElementWH('开', 8)
  const tx = startX - offsetWidth / 2 + 0.25
  const ty = startY + offsetHeight / 2 - 0.5
  carrier.text(tx, ty, '开')
  carrier.setFontSize(pt)
}
// 当前板件需要高亮的
export function highPart(
  part,
  sort_value,
  part_index,
  cur_index,
  template,
  target
) {
  let isSpecial = false
  if (sort_value == 1) {
    if (part_index == part.priority - 1) {
      isSpecial = true
    }
  } else {
    if (cur_index === part_index) {
      isSpecial = true
    }
  }
  if (template.type == 'plank') isSpecial = false
  if (isSpecial) {
    // 特殊标识用于标记当前板件是需要处理的
    Object.defineProperty(target, 'special', {
      value: 102,
      writable: true,
      enumerable: false,
    })
  }
  return isSpecial
}
/**
 *
 * @param { Object } surplus 余料信息
 * @param { Object } ncSetting
 * @param { number } defaultScale
 * @param { number } scale
 * @returns
 */
export function dealSurplusPlank(
  surplus,
  ncSetting,
  defaultScale,
  scale,
  jxChecked
) {
  let path = []
  if (surplus.shape && surplus.shape == 'lshape') {
    const shapePoint = [
      surplus.x3,
      surplus.y3,
      surplus.x4,
      surplus.y4,
      surplus.x5,
      surplus.y5,
    ]
    if (ncSetting.xyReverse) shapePoint.reverse()
    let [x3, y3, x4, y4, x5, y5] = shapePoint
    const newWidth = surplus.width
    const newHeight = surplus.height
    if (
      ncSetting.xyReverse &&
      (ncSetting.startPosition == '右下角' ||
        ncSetting.startPosition == '左上角')
    ) {
      x3 = newWidth - x3
      x4 = newWidth - x4
      x5 = newWidth - x5
      path = [
        {
          x: 0,
          y: !jxChecked ? 0 : pxTomm(newHeight * scale * defaultScale, dpi),
        },
        {
          x: pxTomm(newWidth * scale * defaultScale, dpi),
          y: !jxChecked ? 0 : pxTomm(newHeight * scale * defaultScale, dpi),
        },
        {
          x: pxTomm(newWidth * scale * defaultScale, dpi),
          y: !jxChecked ? pxTomm(newHeight * scale * defaultScale, dpi) : 0,
        },
        {
          x: pxTomm(x3 * scale * defaultScale, dpi),
          y: !jxChecked
            ? pxTomm(y3 * scale * defaultScale, dpi)
            : pxTomm((newHeight - y3) * scale * defaultScale, dpi),
        },
        {
          x: pxTomm(x4 * scale * defaultScale, dpi),
          y: !jxChecked
            ? pxTomm(y4 * scale * defaultScale, dpi)
            : pxTomm((newHeight - y4) * scale * defaultScale, dpi),
        },
        {
          x: pxTomm(x5 * scale * defaultScale, dpi),
          y: !jxChecked
            ? pxTomm(y5 * scale * defaultScale, dpi)
            : pxTomm((newHeight - y5) * scale * defaultScale, dpi),
        },
        {
          x: 0,
          y: !jxChecked ? 0 : pxTomm(newHeight * scale * defaultScale, dpi),
        },
      ]
    } else {
      y3 = newHeight - y3
      y4 = newHeight - y4
      y5 = newHeight - y5
      path = [
        {
          x:
            !jxChecked ||
            (ncSetting.xyReverse &&
              (ncSetting.startPosition == '右上角' ||
                ncSetting.startPosition == '左下角'))
              ? 0
              : pxTomm(newWidth * scale * defaultScale, dpi),
          y: !(
            jxChecked &&
            ncSetting.xyReverse &&
            (ncSetting.startPosition == '右上角' ||
              ncSetting.startPosition == '左下角')
          )
            ? 0
            : pxTomm(newHeight * scale * defaultScale, dpi),
        },
        {
          x:
            !jxChecked ||
            (ncSetting.xyReverse &&
              (ncSetting.startPosition == '右上角' ||
                ncSetting.startPosition == '左下角'))
              ? pxTomm(x3 * scale * defaultScale, dpi)
              : pxTomm((newWidth - x3) * scale * defaultScale, dpi),
          y: !(
            jxChecked &&
            ncSetting.xyReverse &&
            (ncSetting.startPosition == '右上角' ||
              ncSetting.startPosition == '左下角')
          )
            ? pxTomm(y3 * scale * defaultScale, dpi)
            : pxTomm((newHeight - y3) * scale * defaultScale, dpi),
        },
        {
          x:
            !jxChecked ||
            (ncSetting.xyReverse &&
              (ncSetting.startPosition == '右上角' ||
                ncSetting.startPosition == '左下角'))
              ? pxTomm(x4 * scale * defaultScale, dpi)
              : pxTomm((newWidth - x4) * scale * defaultScale, dpi),
          y: !(
            jxChecked &&
            ncSetting.xyReverse &&
            (ncSetting.startPosition == '右上角' ||
              ncSetting.startPosition == '左下角')
          )
            ? pxTomm(y4 * scale * defaultScale, dpi)
            : pxTomm((newHeight - y4) * scale * defaultScale, dpi),
        },
        {
          x:
            !jxChecked ||
            (ncSetting.xyReverse &&
              (ncSetting.startPosition == '右上角' ||
                ncSetting.startPosition == '左下角'))
              ? pxTomm(x5 * scale * defaultScale, dpi)
              : pxTomm((newWidth - x5) * scale * defaultScale, dpi),
          y: !(
            jxChecked &&
            ncSetting.xyReverse &&
            (ncSetting.startPosition == '右上角' ||
              ncSetting.startPosition == '左下角')
          )
            ? pxTomm(y5 * scale * defaultScale, dpi)
            : pxTomm((newHeight - y5) * scale * defaultScale, dpi),
        },
        {
          x:
            !jxChecked ||
            (ncSetting.xyReverse &&
              (ncSetting.startPosition == '右上角' ||
                ncSetting.startPosition == '左下角'))
              ? pxTomm(newWidth * scale * defaultScale, dpi)
              : 0,
          y: !(
            jxChecked &&
            ncSetting.xyReverse &&
            (ncSetting.startPosition == '右上角' ||
              ncSetting.startPosition == '左下角')
          )
            ? pxTomm(newHeight * scale * defaultScale, dpi)
            : 0,
        },
        {
          x:
            !jxChecked ||
            (ncSetting.xyReverse &&
              (ncSetting.startPosition == '右上角' ||
                ncSetting.startPosition == '左下角'))
              ? 0
              : pxTomm(newWidth * scale * defaultScale, dpi),
          y: !(
            jxChecked &&
            ncSetting.xyReverse &&
            (ncSetting.startPosition == '右上角' ||
              ncSetting.startPosition == '左下角')
          )
            ? pxTomm(newHeight * scale * defaultScale, dpi)
            : 0,
        },
        {
          x:
            !jxChecked ||
            (ncSetting.xyReverse &&
              (ncSetting.startPosition == '右上角' ||
                ncSetting.startPosition == '左下角'))
              ? 0
              : pxTomm(newWidth * scale * defaultScale, dpi),
          y: !(
            jxChecked &&
            ncSetting.xyReverse &&
            (ncSetting.startPosition == '右上角' ||
              ncSetting.startPosition == '左下角')
          )
            ? 0
            : pxTomm(newHeight * scale * defaultScale, dpi),
        },
      ]
    }
  }
  return path
}
export function jsPdfTable(
  doc,
  tableData,
  fontSize = 16,
  type = 'extraParts',
  top = 10,
  left = 10,
  fontFamily = '微软雅黑'
) {
  const heads = {
    extraParts: ['名称', '规格', '数量'],
  }

  const magicNums = new Map([
    [42, 2.8],
    [34, 2.5],
    [26, 2.3],
    [21, 2],
    [18, 1.5],
    [16, 1],
    [13, 1],
    [10, 0.7],
    [8, 0.8],
    [5, 0.5],
  ])

  let head = heads[type]
  let data = [head]
  if (tableData && tableData.length) {
    data = data.concat(tableData)
  }

  let columsText = []
  for (let index = 0; index < data[0].length; index++) {
    columsText.push(data.map((e) => e[index]))
  }

  const columsWidth = columsText.map((e) => {
    const widthArr = e.map((text) => +dealElementWH(text, fontSize).offsetWidth)
    return Math.max(...widthArr)
  })

  const rowHeight = +dealElementWH(data[0][0], fontSize).offsetHeight
  doc.setFont(fontFamilyMap[fontFamily])

  //表格总宽度
  let totalWidth = 0
  columsWidth.forEach((width) => {
    totalWidth += width
  })

  // 表格总高度
  let totalHeight = data.length * rowHeight

  // Draw the table rows
  for (let i = 0; i < data.length; i++) {
    let y = top + rowHeight * i
    let x = left
    doc.line(left, y, left + totalWidth, y)
    for (var j = 0; j < data[i].length; j++) {
      if (j) {
        x += columsWidth[j - 1]
        doc.line(x, top, x, top + totalHeight)
      }
      doc.text(
        x +
          1 +
          (columsWidth[j] -
            dealElementWH(data[i][j] + '', fontSize).offsetWidth) /
            2,
        y + magicNums.get(fontSize),
        data[i][j],
        {
          baseline: 'hanging',
        }
      )
    }
  }

  // Draw rectangles around the cells
  doc.rect(left, top, totalWidth, totalHeight)
}

// 特殊标记
export function drawSpecialSign(
  doc,
  sign,
  template,
  opacity = 0.24,
  sizeAuto = true,
  size = 1,
  op
) {
  if (!sign || sign === 'default' || template.type === 'plank') return
  // 自定义特殊标签的标志位、解构
  const cusSpecialFlag = op && Reflect.ownKeys(op).length > 0
  const { startX, startY, custSpeciialTagratioX, custSpeciialTagratioY } =
    op ?? {}
  const { width: widthBase, height: heightBase } = doc.internal.pageSize
  const center = !cusSpecialFlag
    ? { x: widthBase / 2, y: heightBase / 2 }
    : { x: startX, y: startY }
  const diameter = 75
  if (sizeAuto) {
    size = 1
  }
  const ratio = Math.min(widthBase / diameter, heightBase / diameter) * size

  // 更改整体页面填充以及边框透明度
  doc.setGState(doc.GState({ opacity, 'stroke-opacity': opacity }))
  const scale = 0.92
  switch (sign) {
    case 'triangle_shape':
    case 'square_shape':
    case 'hexagon_shape':
      drawPathShape(sign)
      break
    case 'circle_shape':
      drawCircleShape()
      break
    case 'half_circle_shape':
      drawHalfCircle()
      break
  }
  function drawPathShape() {
    const path = signShape[sign].map((it) => ({
      x: it.x * (custSpeciialTagratioX ?? ratio),
      y: it.y * (custSpeciialTagratioY ?? ratio),
    }))
    path[path.length] = { ...path[0] }
    // 外边框
    const [minx, miny, maxx, maxy] = getPathMinMaxPoint(path)
    const width = maxx - minx
    const height = maxy - miny
    const shapeInstance = new DocCombinationShape(
      path,
      [],
      center.x - width / 2,
      center.y - height / 2
    )
    shapeInstance.drawGroup(doc)
    // 内部图形
    const littlePath = path.map((it) => ({ x: it.x * scale, y: it.y * scale }))
    const [lminx, lminy, lmaxx, lmaxy] = getPathMinMaxPoint(littlePath)
    const lwidth = lmaxx - lminx
    const lheight = lmaxy - lminy
    defineObjProp(littlePath, 'special', '0')
    defineObjProp(littlePath, 'hiddenBorder', true)
    // 重置绘制所需参数
    shapeInstance.resetParams(
      littlePath,
      [],
      center.x - lwidth / 2,
      center.y - lheight / 2
    )
    shapeInstance.drawGroup(doc)
  }
  function drawCircleShape() {
    if (!cusSpecialFlag) {
      doc.circle(center.x, center.y, 25 * ratio)
      doc.circle(center.x, center.y, 25 * ratio * scale, 'F')
    } else {
      doc.ellipse(
        center.x,
        center.y,
        25 * custSpeciialTagratioX,
        25 * custSpeciialTagratioY
      )
      doc.ellipse(
        center.x,
        center.y,
        25 * custSpeciialTagratioX * scale,
        25 * custSpeciialTagratioY * scale,
        'F'
      )
    }
  }
  function drawHalfCircle() {
    // 自定义特殊标签的半径用值小的
    const othRadio = cusSpecialFlag
      ? Math.min(custSpeciialTagratioX, custSpeciialTagratioY)
      : ratio
    const ctx = doc.context2d
    const radius = 25 * othRadio
    const littleRadius = radius * scale
    ctx.lineWidth = 0.2
    const offset = radius / 4
    ctx.arc(center.x, center.y + offset, radius, 0, Math.PI, true)
    ctx.moveTo(center.x - radius, center.y + offset)
    ctx.lineTo(center.x + radius, center.y + offset)
    ctx.stroke()
    ctx.beginPath()
    ctx.arc(center.x, center.y + offset * scale, littleRadius, 0, Math.PI, true)
    ctx.fill()
  }
  // 恢复页面整体填充和边框透明度
  doc.setGState(doc.GState({ opacity: 1, 'stroke-opacity': 1 }))
}
